<template>
    <modal ref="modalSelecAvatar" titulo="Selecionar Avatar" tamano="modal-lg" no-cancelar no-aceptar no-adicional>
        <div class="row mx-0 j-center px-4 mt-4">
            <img 
            v-for="(item, key) in avatars" 
            :key="key"
            :src="item.foto"
            class="obj-cover border rounded-circle mx-3" 
            width="65" 
            height="65" 
            @click="editAvatar(item.id)"
            />
        </div>
    </modal>
</template>

<script>
import Service from '~/services/cliente/cliente';
import Avatares from '~/services/avatares/avatares';
export default {
    data(){
        return {
            avatars:[]
        }
    },
    methods: {
        toggle(){
            this.listAvatar();
            this.$refs.modalSelecAvatar.toggle();
        },
        async listAvatar(){
            try{
                const { data } = await Avatares.getAvatares();
                this.avatars = data.avatars
            }catch(error){
                this.errorCatch(error)
            }
        },
        async editAvatar(item){
            try{
                const payload = {
                    id_avatar: item,
                };
                const { data } = await Service.editAvatar(payload);
                this.$emit("update", data);
                this.$refs.modalSelecAvatar.toggle();
                this.$notify({
                    title: 'Datos actualizados',
                    message: data.mensaje,
                    type: 'success'
                });
            }catch(error){
                this.errorCatch(error)
            }
        }
    }
}
</script>