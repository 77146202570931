import axios from 'axios';

const API = 'avatar';

const ENDPOINTS = {
    getAvatares(){
        return axios.get(`${API}/list`)
    },
};

export default ENDPOINTS;